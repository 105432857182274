import { ITruckOptionsPayload } from '@truckmap/common';
import { CurrentUserSettings } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userMetasAtom } from 'recoil/user';

export const useCurrentUserOptions = ({
  truckOptionsPayload
}: {
  truckOptionsPayload: ITruckOptionsPayload;
}): CurrentUserSettings => {
  const userMetas = useRecoilValue(userMetasAtom);

  const convertedKey = {
    equipmentWeights: 'equipmentWeightId',
    equipmentHeights: 'equipmentHeightId',
    equipmentLengths: 'equipmentLengthId',
    equipmentCommercialTypes: 'equipmentCommercialTypeId',
    equipmentTypes: 'equipmentTypeId',
    hazmatClasses: 'hazmatClassId',
    fuelTypes: 'fuelTypeId',
    avoidTollways: 'avoidTollwaysId'
  };

  return useMemo(() => {
    if (!truckOptionsPayload || !userMetas || !Object.keys(truckOptionsPayload).length) return;

    const finalObject = {};
    Object.keys(truckOptionsPayload.truckOptionsList).forEach((key) => {
      const currentOptionList = truckOptionsPayload.truckOptionsList[key];
      const id =
        userMetas[convertedKey[key]] ||
        currentOptionList.find(({ default: isDefault }) => isDefault)?.id;
      const optionItem = currentOptionList.find(({ id: currentId }) => currentId === id);

      finalObject[key] = optionItem;
    });

    return finalObject;
  }, [truckOptionsPayload, userMetas]);
};
