'use client';
import { serializePage } from 'lib/contentful/contentfulSerializers';
import { createContext, useContext, useMemo } from 'react';
import { PageContextTypes } from 'types/pages/PageContextTypes';

export function createPageContext() {
  return createContext<PageContextTypes>(null);
}

export const PageContext = createPageContext();

export const usePageContext = () => useContext(PageContext);

export const usePageSerializedContext = () => {
  const pageCtx = usePageContext();
  const { page, ...customFields } = pageCtx;

  const serializedPage = useMemo(() => page && serializePage(page), [page]);

  return useMemo(
    () => ({
      ...customFields,
      ...serializedPage
    }),
    [pageCtx]
  );
};
