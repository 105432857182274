import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import { useFormState } from 'react-hook-form';
import { FullWidthProp } from 'types/common';

import { Button } from '../Button';

export type Props = {
  isSmall?: boolean;
  disabled?: boolean;
  label?: string;
  defaultPadding?: boolean;
} & FullWidthProp;

const useStyles = makeStyles({
  container: (props: Props) => [
    {
      'p-1': props.defaultPadding,
      'w-full': props.fullWidth
    }
  ]
});

export const FormSubmitButton = ({
  isSmall,
  disabled,
  label,
  defaultPadding = true,
  fullWidth = false
}: Props) => {
  const styles = useStyles({ defaultPadding, fullWidth });
  const { t } = useTranslation();
  const { isValid, isSubmitting } = useFormState();

  return (
    <div className={styles.container}>
      <Button
        isSmall={isSmall}
        disabled={(!isValid || isSubmitting) ?? disabled}
        isPrimary
        fullWidth>
        {label ?? t('BUTTON_SUBMIT')}
      </Button>
    </div>
  );
};

FormSubmitButton.displayName = 'FormSubmitButton';
