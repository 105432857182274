import { PanelCommonStyles, PanelFlexProps } from 'components/layout/Panel/PanelFlex';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import {
  PanelPaddingProps as PanelPaddingStyleProps,
  usePanelPaddingStyles
} from 'styles/panelStyles';

export type PanelPaddingProps = PanelPaddingStyleProps &
  Pick<PanelFlexProps, 'as'> &
  ChildrenProp &
  ParentClassNameProp;

const useStyles = makeStyles<PanelPaddingProps & Pick<PanelCommonStyles, 'paddingStyles'>>()({
  root: (props) => ['w-full h-full', props.className, props.paddingStyles]
});

export const PanelPadding: React.FC<PanelPaddingProps> = React.memo(
  ({ as = 'div', padding = true, paddingX = 'MD', paddingY = 'MD', children, ...restProps }) => {
    const props = {
      as,
      padding,
      paddingX,
      paddingY,
      children,
      ...restProps
    };
    const paddingStyles = usePanelPaddingStyles(props);

    const styles = useStyles({
      paddingStyles: paddingStyles.padding,
      ...props
    });

    const WrapperElement = as;

    return <WrapperElement className={styles.root}>{children}</WrapperElement>;
  }
);

PanelPadding.displayName = 'PanelPadding';
