'use client';
import { loadScript } from 'lib/loadScript';
import { usePathname } from 'next/navigation';
import { useEffect, useLayoutEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { driftWidgetAtom } from 'recoil/driftWidget';
import { truckMapConfig } from 'truckMapConfig';

export const DRIFT_FRAME_CONTROLLER_ID = 'drift-frame-controller';
export const DRIFT_FRAME_CHAT_ID = 'drift-frame-chat';
export const DRIFT_HIDE_CLASS = 'hide-drift';
export const DRIFT_DISABLED_PAGES = ['/map', '/places', '/tracking'];

let driftLoaderLock: boolean;
let driftLoaded: boolean;

export const updateDriftWidgetClassList = (hideWidget: boolean) => {
  const driftFrameController = document.getElementById(DRIFT_FRAME_CONTROLLER_ID);
  const driftFrameChat = document.getElementById(DRIFT_FRAME_CHAT_ID);

  if (hideWidget) {
    driftFrameChat?.classList.add(DRIFT_HIDE_CLASS);
    return driftFrameController?.classList.add(DRIFT_HIDE_CLASS);
  }

  driftFrameChat?.classList.remove(DRIFT_HIDE_CLASS);
  driftFrameController?.classList.remove(DRIFT_HIDE_CLASS);
};

/**
 * Add and remove CSS class for controlling Drift widget visibility
 * @returns useLayoutEffect to add/remove CSS class
 */
export const useDriftWidgetScript = () => {
  const pathname = usePathname();
  const isPageDisabled = DRIFT_DISABLED_PAGES.some((page) => pathname.startsWith(page));

  if (
    truckMapConfig.isSearchBot ||
    truckMapConfig.isTenstreet ||
    isPageDisabled ||
    truckMapConfig.env !== 'production'
  )
    return;

  const hideDriftWidget = useRecoilValue(driftWidgetAtom);

  useEffect(() => {
    const facadeEvent = 'mousemove';
    let scriptLoaded = false;

    const scriptFacade = () => {
      if (!scriptLoaded) {
        scriptLoaded = true;
        loadScript('/scripts/drift.js').then(() => {
          driftLoaded = true;
        });
        document.removeEventListener(facadeEvent, scriptFacade);
      }
    };

    if (!truckMapConfig.isSearchBot) {
      driftLoaderLock = false;
    }

    if (!truckMapConfig.isSearchBot && !driftLoaderLock) {
      driftLoaderLock = true;
      document.addEventListener(facadeEvent, scriptFacade);
    }

    return () => {
      if (scriptLoaded && window.drift) {
        window.drift.api.widget.hide();
        const script = document.querySelector('script[src="/scripts/drift.js"]');
        if (script) {
          script.remove();
        }
      }
      document.removeEventListener(facadeEvent, scriptFacade);
    };
  }, [pathname]);

  return useLayoutEffect(() => {
    if (driftLoaded) {
      updateDriftWidgetClassList(hideDriftWidget);
    }
  }, [hideDriftWidget]);
};

/**
 * Hide Drift widget
 * @param hideWidget boolean
 * @returns useLayoutEffect to add/remove CSS class
 */
export const useHideDriftWidget = (hideWidget = true) => {
  const setDriftWidgetVisibility = useSetRecoilState(driftWidgetAtom);

  return useLayoutEffect(
    () => setDriftWidgetVisibility(hideWidget),
    [setDriftWidgetVisibility, hideWidget]
  );
};
