import { updateDriftWidgetClassList } from 'hooks/useDriftWidget';
import { atom } from 'recoil';

export const driftWidgetAtom = atom<boolean>({
  key: 'driftWidgetAtom',
  default: true,
  effects: [
    ({ onSet }) => {
      onSet((hideWidget) => {
        updateDriftWidgetClassList(hideWidget);
      });
    }
  ]
});
