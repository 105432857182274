import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';

export type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: SubmitHandler<any>;
  inline?: boolean;
  defaultPadding?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  config?: UseFormProps;
} & JSX.IntrinsicElements['form'] &
  ChildrenProp;

const useStyles = makeStyles({
  root: ['w-full h-full'],
  formInner: (props: Props) => [
    {
      'flex flex-row justify-start items-center gap-x-1.5': props.inline,
      'p-1': props.defaultPadding,
      'h-full': props.fullHeight,
      'w-full': props.fullWidth
    },
    props.className
  ]
});

export const Form: React.FC<Props> = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      children,
      inline,
      config,
      defaultPadding = true,
      fullHeight = false,
      fullWidth = true,
      ...formProps
    },
    ref
  ) => {
    const styles = useStyles({ inline, defaultPadding, fullHeight, fullWidth, ...formProps });
    const { onSubmit } = { ...formProps };
    const methods = useForm({ mode: 'all', ...config });

    return (
      <FormProvider {...methods}>
        <form
          ref={ref}
          className={styles.root}
          onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
          <div className={styles.formInner}>{children}</div>
        </form>
      </FormProvider>
    );
  }
);

Form.displayName = 'Form';
